//
// user.js
// Use this to write your custom JS
//

function targetBlank() {
  // remove subdomain of current site's url and setup regex
  var internal = location.host.replace('www.', '');
  internal = new RegExp(internal, 'i');

  var a = document.getElementsByTagName('a'); // then, grab every link on the page
  for (var i = 0; i < a.length; i++) {
    var href = a[i].host; // set the host of each link
    if (!internal.test(href)) {
      // make sure the href doesn't contain current site's host
      a[i].setAttribute('target', '_blank'); // if it doesn't, set attributes
    }
  }
}

targetBlank();

window.moveModals = function () {
  var modals = document.getElementsByClassName('move-modal');
  for (var i = 0; i < modals.length; i++) {
    var modal = modals[i];
    document.body.appendChild(modal);
  }
};
window.moveModals();
document.addEventListener(
  'DOMContentLoaded',
  function () {
    window.moveModals();
    setTimeout(window.moveModals, 2000);
  },
  false
);
